import { IconNames } from '@lualtek/icons';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useTranslate } from '../i18n';
import { useCurrentQuery } from './use-current-query';

type AllRoutesKeys =
|'dashboard'
|'automations'
|'areas'
|'analysis'

type RouterState = {
  i18nKey: string;
  href: (...params: number[] | string[]) => string;
  icon: IconNames;
  pathname: string;
};

export const ConsoleRoutes: Record<AllRoutesKeys, RouterState> = {
  dashboard: {
    i18nKey: 'common:menu.dashboard.label',
    href: (appId: number) => `/app/${appId}/dashboard`,
    icon: 'dashboard',
    pathname: '/app/[appid]/dashboard',
  },
  automations: {
    i18nKey: 'common:menu.automations.label',
    href: (appId: number) => `/app/${appId}/automations`,
    icon: 'decentralize',
    pathname: '/app/[appid]/automations',
  },
  areas: {
    i18nKey: 'common:menu.areas.label',
    href: (appId: number) => `/app/${appId}/areas`,
    icon: 'zone',
    pathname: '/app/[appid]/areas',
  },
  analysis: {
    i18nKey: 'common:menu.analysis.label',
    href: (appId: number) => `/app/${appId}/analysis`,
    icon: 'pulse',
    pathname: '/app/[appid]/analysis',
  },
};

type PathToProps =
{
  kind: 'new-automation';
  entityId?: never;
  fatherEntityId?: never;
} | {
  kind: 'automations';
  entityId?: never;
  fatherEntityId?: never;
} | {
  kind: 'areas';
  entityId?: never;
  fatherEntityId?: never;
} | {
  kind: 'area-detail';
  entityId: number;
  fatherEntityId?: never;
} | {
  kind: 'area-edit';
  entityId: number;
  fatherEntityId?: never;
} | {
  kind: 'new-area';
  entityId?: number;
  fatherEntityId?: never;
} | {
  kind: 'device-area-detail';
  entityId: number;
  fatherEntityId: number;
} | {
  kind: 'device-detail';
  entityId: number;
  fatherEntityId?: never;
} | {
  kind: 'automation-detail';
  entityId: number;
  fatherEntityId?: never;
} | {
  kind: 'new-analysis';
  entityId?: never;
  fatherEntityId?: never;
} | {
  kind: 'new-analysis-kind';
  entityId: string;
  fatherEntityId?: never;
} | {
  kind: 'analysis';
  entityId?: never;
  fatherEntityId?: never;
} | {
  kind: 'analysis-detail';
  // New method with slug instead of id
  entityId: string;
  fatherEntityId?: never;
} | {
  kind: 'dashboard';
  // New method with slug instead of id
  entityId?: never;
  fatherEntityId?: never;
};

export const useNavigationHelpers = () => {
  const { appid } = useCurrentQuery();
  const pathTo = useCallback(({ kind, entityId, fatherEntityId }: PathToProps) => {
    switch (kind) {
      case 'new-automation':
        return `/app/${appid}/automations/new`;
      case 'areas':
        return `/app/${appid}/areas`;
      case 'area-detail':
        return `/app/${appid}/areas/${entityId}`;
      case 'area-edit':
        return `/app/${appid}/areas/${entityId}/edit`;
      case 'new-area':
        return `/app/${appid}/areas/new`;
      case 'device-area-detail':
        return `/app/${appid}/areas/${fatherEntityId}/${entityId}`;
      case 'device-detail':
        return `/app/${appid}/devices/${entityId}`;
      case 'automation-detail':
        return `/app/${appid}/automations/${entityId}`;
      case 'automations':
        return `/app/${appid}/automations`;
      case 'new-analysis':
        return `/app/${appid}/analysis/new`;
      case 'new-analysis-kind':
        return `/app/${appid}/analysis/new?kind=${entityId}`;
      case 'analysis':
        return `/app/${appid}/analysis`;
      case 'analysis-detail':
        return `/app/${appid}/analysis/${entityId}`;
      case 'dashboard':
        return `/app/${appid}/dashboard`;
      default:
        return '';
    }
  }, [appid]);

  return {
    pathTo,
  };
};

export const useNavigation = () => {
  const router = useRouter();
  const { t } = useTranslate();
  const { appid } = useCurrentQuery();

  const mainMenu = useMemo(() => Object.keys(ConsoleRoutes).map((keyRoute) => {
    const routeData = ConsoleRoutes[keyRoute as keyof typeof ConsoleRoutes];
    const href = routeData.href(appid);
    return {
      label: t(routeData.i18nKey),
      href,
      active: router.asPath.includes(href),
      icon: routeData.icon,
    };
  }), [appid, router.asPath, t]);

  return {
    mainMenu,
  };
};
